import type { FunctionComponent } from 'react';
import type { CustomArrowProps } from 'react-slick';
import Slider from 'react-slick';
import type { BentoOneFragment } from '../../../../graphql/queries/GetBentoOne.graphql';
import BentoPieceRectangle from '../../pieces/bento-piece-rectangle';
import styles from './bento-one.module.scss';
export const BentoOne: FunctionComponent<Props> = ({
  pieces
}) => {
  const NextArrow = ({
    currentSlide,
    slideCount,
    ...props
  }: CustomArrowProps) => <div {...props} data-sentry-component="NextArrow" data-sentry-source-file="bento-one.tsx" />;
  const PrevArrow = ({
    currentSlide,
    slideCount,
    ...props
  }: CustomArrowProps) => <div {...props} data-sentry-component="PrevArrow" data-sentry-source-file="bento-one.tsx" />;
  return <div className={styles.container} data-sentry-component="BentoOne" data-sentry-source-file="bento-one.tsx">
            <div className={styles.flexContainer}>
                {pieces?.items.length === 1 && <BentoPieceRectangle rectangle={pieces?.items[0]} />}

                {/* If more than 1 item returned use inside slider element */}
                {pieces && pieces?.items?.length > 1 && <Slider accessibility autoplay autoplaySpeed={7000} className={styles.carousel} infinite nextArrow={<NextArrow />} prevArrow={<PrevArrow />}>
                        {pieces?.items.map((item, index) => {
          return <div key={index}>
                                    <BentoPieceRectangle rectangle={item} />
                                </div>;
        })}
                    </Slider>}
            </div>
        </div>;
};
export default BentoOne;
type Props = Omit<BentoOneFragment, 'sys' | 'type'> & {
  index?: number;
};